import React from "react"
import Layout from "../components/layout"

const Home = () => (
  <Layout>
    <p>Home</p>
  </Layout>
)

export default Home
